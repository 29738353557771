.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.parent { 
	display: grid;
	gap: 2rem;
	grid-template-columns: 2fr 1fr 1fr;
	font-family: "Roboto", sans-serif;
	border-bottom: 1px solid var(--shadow-gray);
	padding: 0 0 1rem 0;
}


.parent.banner-center .secondary-content{
	order: 3;
}

.parent.banner-center .banner {
	order: 2;
}

.parent.banner-right .secondary-content{
	order: 2;
}

.parent.banner-right .banner {
	order: 3;
}

.main-content { 
	width: 100%;
	order: 1;
}

.banner{
	min-width: 250px;
}

.parent h5 { 
	font-size: 1rem;
	font-weight: 400;
	margin-top: 1.5rem;
}

.main-container h5{
	margin-top: 2rem;
}

.main-container img {
	width: 100%;
}

.main-container{
	margin-bottom: 2rem;
}

.secondary-content {
	width: 100%;
}

.secondary-container {
	border-top: 1px solid var(--shadow-gray);
	padding: 1rem 0;
}

.secondary-container:first-child {
	padding: 0 0 0.7rem;
	border-top: 0;
}

.main-txt-container { 
	width: 100%;
}

.main-container h2 { 
	color: var(--dark-black);
	font-size: 1.8rem;
	line-height: 2rem;
	font-weight: 400;
}

.secondary-txt-container h3 {
	display: inline;
	font-size: 1.25rem;
	font-weight: 400;
	color: var(--sky-blue);
}

.secondary-txt-container span {
	display: inline;
	font-size: 1.25rem;
	font-weight: 400;
	margin: 0;
	line-height: 1.3;
	color: var(--dark-black);
}

.summary p{ 
	color: var(--gray-text);
	font-size: 1rem;
	font-weight: 400;
}

.secondary-txt-container {
	margin-top: 0.5rem;
}

@media(max-width: 980px) {
	.parent {
		grid-template-columns: 1fr 1fr;
	}

	.main-content {
		grid-column: 1 / -1;
	}
}

@media(max-width: 650px) {
	.parent {
		grid-template-columns: 1fr;
	}

	.main-container h2 {
		font-size: 1.5rem;
		line-height: 1.6rem;
	}
	.main-container{
		margin-bottom: 0rem;
	}
}